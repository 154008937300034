var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/index.ts
var HOME_URL = "/";
var LOGIN_URL = "/login";
var REGISTER_URL = "/register";
var MY_PROFILE_URL = "/my-profile";
var MY_PERSONAL_ACCOUNT_URL = "/my-personal-account";
var SHARED_MEALS_URL = "/shared-meals";
var VOLUNTEERS_URL = "/volunteers";
var AVAILABILITIES_URL = "/availabilities";
var PLANNING_URL = "/planning";
var FA_URL = "/fa";
var FT_URL = "/ft";
var FA_TO_PUBLISH_URL = "/fa/to-publish";
var SECURITY_DASHBOARD_URL = "/fa/dashboard/security";
var STATS_URL = "/stats";
var GEAR_CATALOG_URL = "/logistic/catalog";
var INVENTORY_URL = "/logistic/inventory";
var GEAR_DASHBOARD_URL = "/logistic/dashboard";
var PURCHASE_GEARS_URL = "/logistic/purchase";
var BORROW_GEARS_URL = "/logistic/borrow";
var LOCATION_URL = "/signa/location";
var SIGNAGE_CATALOG_URL = "/signa/catalog";
var CONFIGURATION_URL = "/configuration";
var REGISTRATIONS_STAFF_URL = "/registrations/staff";
var REGISTRATIONS_VOLUNTEER_URL = "/registrations/volunteer";
var CONTRIBUTIONS_URL = "/contributions";
var SG_URL = "/sg";
var TRANSACTIONS_URL = "/transactions";
var CHARISMA_EVENTS_MANAGE_URL = "/charisma/events/manage";
var CHARISMA_EVENTS_LIST_URL = "/charisma/events/list";
var CHARISMA_PERIODS_URL = "/charisma/periods";
var ASSIGNMENT_ORGA_TASK_URL = "/assignment/orga-task";
var ASSIGNMENT_TASK_ORGA_URL = "/assignment/task-orga";
var ORGA_NEED_URL = "/orga-need";
var TIMELINE_URL = "/timeline";
var NEED_HELP_URL = "/need-help";
var pagesURL = [
  HOME_URL,
  MY_PROFILE_URL,
  MY_PERSONAL_ACCOUNT_URL,
  SHARED_MEALS_URL,
  VOLUNTEERS_URL,
  AVAILABILITIES_URL,
  PLANNING_URL,
  FA_URL,
  FT_URL,
  FA_TO_PUBLISH_URL,
  SECURITY_DASHBOARD_URL,
  STATS_URL,
  GEAR_CATALOG_URL,
  INVENTORY_URL,
  GEAR_DASHBOARD_URL,
  PURCHASE_GEARS_URL,
  BORROW_GEARS_URL,
  LOCATION_URL,
  SIGNAGE_CATALOG_URL,
  CONFIGURATION_URL,
  REGISTRATIONS_STAFF_URL,
  REGISTRATIONS_VOLUNTEER_URL,
  CONTRIBUTIONS_URL,
  SG_URL,
  TRANSACTIONS_URL,
  CHARISMA_EVENTS_MANAGE_URL,
  CHARISMA_EVENTS_LIST_URL,
  CHARISMA_PERIODS_URL,
  ASSIGNMENT_ORGA_TASK_URL,
  ASSIGNMENT_TASK_ORGA_URL,
  ORGA_NEED_URL,
  TIMELINE_URL,
  NEED_HELP_URL
];
function isPageURL(url) {
  return pagesURL.some((page) => page === url);
}
__name(isPageURL, "isPageURL");
export {
  ASSIGNMENT_ORGA_TASK_URL,
  ASSIGNMENT_TASK_ORGA_URL,
  AVAILABILITIES_URL,
  BORROW_GEARS_URL,
  CHARISMA_EVENTS_LIST_URL,
  CHARISMA_EVENTS_MANAGE_URL,
  CHARISMA_PERIODS_URL,
  CONFIGURATION_URL,
  CONTRIBUTIONS_URL,
  FA_TO_PUBLISH_URL,
  FA_URL,
  FT_URL,
  GEAR_CATALOG_URL,
  GEAR_DASHBOARD_URL,
  HOME_URL,
  INVENTORY_URL,
  LOCATION_URL,
  LOGIN_URL,
  MY_PERSONAL_ACCOUNT_URL,
  MY_PROFILE_URL,
  NEED_HELP_URL,
  ORGA_NEED_URL,
  PLANNING_URL,
  PURCHASE_GEARS_URL,
  REGISTER_URL,
  REGISTRATIONS_STAFF_URL,
  REGISTRATIONS_VOLUNTEER_URL,
  SECURITY_DASHBOARD_URL,
  SG_URL,
  SHARED_MEALS_URL,
  SIGNAGE_CATALOG_URL,
  STATS_URL,
  TIMELINE_URL,
  TRANSACTIONS_URL,
  VOLUNTEERS_URL,
  isPageURL,
  pagesURL
};
